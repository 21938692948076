/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onInitialClientRender = () =>{
    document.getElementById("___loader").style.opacity = 0;
    setTimeout(()=>{
        document.getElementById("___loader").style.display="none";
    },2000)
}