import React from 'react';
import styled from '@emotion/styled';


import './Layout.css';

const Main = styled.main`
    width: 100vw;
    height: 100vh;
    position:relative;
    color: white;
`;

const Layout = ({children}) =>{
    return(
        <>
        <Main>
            {children}
        </Main>
        </>
    )
}

export default Layout;